import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  CardActions,
  Checkbox,
  Collapse,
  createFilterOptions,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import Layout from "../layout";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { MultipleUserAutocomplete } from "../../autocomplete";
import { useFetch } from "../utilities";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function AnnouncementsPage() {
  return (
    <Layout>
      <Toolbar sx={{ marginY: 2 }} />
      <AnnouncementList />
    </Layout>
  );
}

const METHOD_POST = "POST";

function AnnouncementForm(props) {
  const { successCallback } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [content, setContent] = useState();
  const [targetUsers, setTargetUsers] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState(Math.random());
  const accessToken = useFetch();

  useEffect(() => {}, [targetUsers]);

  const submitFormHandler = (event) => {
    setButtonDisabled(true);
    event.preventDefault();

    const data = {
      method: METHOD_POST,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        content,
        target_ids: targetUsers.map((item) => item.id),
      }),
    };

    const url = `${process.env.REACT_APP_API_URL}/api/v1/admin/announcements/`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          successCallback();
          setButtonDisabled(false);
          setContent("");
          setTargetUsers([]);
          setAutocompleteKey(Math.random());
        } else if (response.status === 401) {
          alert("Unauthorized. Please login again.");
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Card sx={{ width: 800 }}>
      <CardContent>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
            setContent(editor.getData());
          }}
        />
        <MultipleUserAutocomplete
          textFieldLabel={"Select users to receive the announcements"}
          authorsBool={"true"}
          sx={{ marginTop: 3 }}
          key={autocompleteKey}
          value={targetUsers}
          multiple={true}
          onChangeMultiple={(e, value, mainList) => {
            if (value.find((option) => option.all)) {
              setTargetUsers(value.length === mainList.length ? [] : mainList);
            } else {
              setTargetUsers(value ? value : []);
            }
          }}
          filterOptions={(options, params) => {
            const filter = createFilterOptions();
            const filtered = filter(options, params);
            return [{ display_name: "Select All...", all: true }, ...filtered];
          }}
          renderOptionMultiple={(props, option, selected, mainList) => {
            return (
              <li {...props} key={option.display_name}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={
                    option.all
                      ? targetUsers.length === mainList.length
                      : selected
                  }
                />
                {option.display_name}
              </li>
            );
          }}
        />
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginY: 1 }}>
        <Button
          disabled={buttonDisabled}
          onClick={submitFormHandler}
          sx={{ marginX: 1 }}
          color={"primary"}
          variant="outlined"
          aria-label="save"
        >
          Save
        </Button>
      </Box>
    </Card>
  );
}

function AnnouncementList() {
  const [announcements, setAnnouncements] = useState([]);
  const accessToken = useFetch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      getAnnouncements();
    }
  }, [accessToken]);

  const getAnnouncements = () => {
    const data = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v1/admin/announcements/`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          alert("Unauthorized. Please login again.");
        } else {
          console.log("Error");
        }
      })
      .then((data) => {
        setAnnouncements(data.announcements);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ marginX: "auto" }}>
      <AnnouncementForm successCallback={getAnnouncements} />

      <Grid container spacing={3}>
        {announcements &&
          announcements.map((item) => (
            <Grid item xs key={item.id}>
              <AnnouncementCard announcement={item} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

function AnnouncementCard(props) {
  const [users, setUsers] = useState([]);
  const { announcement } = props;
  const [expanded, setExpanded] = useState(false);
  const accessToken = useFetch();

  const getAnnouncement = () => {
    const data = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v1/admin/announcements/${announcement.id}/`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          alert("Unauthorized. Please login again.");
        } else {
          console.log("Error");
        }
      })
      .then((data) => {
        setUsers(data.users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    getAnnouncement();
  };
  return (
    <Card sx={{ width: 600, marginY: 3 }} color={"secondary"}>
      <CardContent sx={{ height: 200, overflow: "auto" }}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: announcement.message,
          }}
        ></Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>{"Users"}</Typography>
          <DataGrid
            autoHeight
            columns={[
              {
                field: "id",
                headerName: "ID",
                flex: 0,
              },
              {
                field: "display_name",
                headerName: "Username",
                flex: 1,
                minWidth: 200,
              },
            ]}
            rows={users}
            sx={{ "--DataGrid-overlayHeight": "300px" }}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}
