import Posts from "./components/posts/lists";
import Toolbar from "@mui/material/Toolbar";
import Layout from "./components/layout";


function App() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 1}}/>
      <Posts/>
    </Layout>
  );
}

export default App;
