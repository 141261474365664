import Box from "@mui/material/Box";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthenticatedUserContext} from "../../context";
import {Chip} from "@mui/material";
import dayjs from "dayjs";
import {ListCardHeader, ListCardHeaderAction, PostCardActions, PostCardContent, PostList} from "./item";
import {CommentCardContent} from "./comments";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";


export function BookmarkComment(props) {
  const [comment, setComment] = useState({
    id: props.post.id,
    title: props.post.title || '',
    submitter: props.post.submitter,
    text: props.post.text,
    up_vote_count: props.post.up_vote_count,
    down_vote_count: props.post.down_vote_count,
    level: props.post.level,
    parent_id: props.post.parent_id,
    status: props.post.status === 'public' ? null : props.post.status,
    datetime_posted: props.post.datetime_posted,
    votes: props.post.votes || [],
    topic: props.post.topic,
    bookmarks: props.post.bookmarks || [],
    comments_count: props.post.comments_count,
  })
  const [levelLimit, setLevelLimit] = useState(props.levelLimit)
  const [showComments, setShowComments] = useState(true)
  const [comments, setComments] = useState(props.post.comments || [])
  const {highLightedTexts, cardActionList} = props
  useEffect(() => {
    setLevelLimit(props.levelLimit)
  }, [props.levelLimit])
  const commentsEl = comments.length > 0 && comments.map((comment) => (
    <BookmarkComment cardActionList={cardActionList} highLightedTexts={highLightedTexts} key={comment.id}
                     levelLimit={levelLimit} post={comment}/>
  ))
  return (
    <Card sx={{
      marginTop: 1,
      padding: 0,
      borderColor: 'grey.500',
      marginBottom: 0,
      marginLeft: comment.level > 0 ? 1 : 0,
      borderLeft: comment.level > 0 ? 1 : 0,
    }} elevation={0}>
      <ListCardHeader
        id={comment.id}
        submitter={comment.submitter}
        datetime_posted={comment.datetime_posted}
        topic={!!comment.topic ? (<Typography sx={{fontSize: 'inherit'}}
                                              component="span">{comment.topic.name.toUpperCase()}</Typography>) : null}
        action={<ListCardHeaderAction post={comment} setPost={setComment}/>}
      />
      <CommentCardContent highLightedTexts={highLightedTexts} text={comment.text}/>
      <PostCardActions
        id={comment.id}
        votes={comment.votes}
        level={comment.level}
        tags={comment.tags}
        up_vote_count={comment.up_vote_count}
        down_vote_count={comment.down_vote_count}
        comments_count={comment.comments_count}
        showComments={showComments && levelLimit !== comment.level}
        parent_datetime_posted={comment.datetime_posted}
        setShowComments={setShowComments}
        successVoteCallback={data => {
          setComment(data)
        }}
        levelLimit={levelLimit}
        setComments={(newComment) => {
          setComments([...comments, newComment])
        }}
        actionList={cardActionList || ['upvote', 'downvote', 'flag', 'share']}
      />
      <Box>
        {showComments ? commentsEl : null}
      </Box>
    </Card>
  )
}


export function Bookmark(props) {
  const {post, comments, cardActionList} = props
  const attributes = {}
  if (!!cardActionList) {
    attributes.cardActionList = cardActionList
  }
  return (
    <>
      <PostList
        cardContent={<PostCardContent title={post.title} text={post.text}/>}
        {...attributes}
        key={post.id}
        post={post}/>
      {comments && comments.map(comment => <BookmarkComment {...attributes} key={comment.id} post={comment}/>)}
    </>
  )
}


export function Bookmarks() {
  const [posts, setPosts] = useState({})
  const {firebase_user, isVerified, getBackendToken} = useContext(AuthenticatedUserContext)
  const pulledBookmarks = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  const getBookmarks = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/bookmarks/`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    }).then(data => {
      setPosts(data)
    })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    async function setupAccessToken() {
      if (!accessToken) {
        setAccessToken(await getBackendToken())
      }
    }

    if (accessToken && isVerified && !pulledBookmarks.current) {
      setupAccessToken().then(() => {
        getBookmarks()
        pulledBookmarks.current = true
      })
    }
    setupAccessToken()
  }, [firebase_user, accessToken])

  return (
    <Box>
      {
        Object.keys(posts).map(datetime => {
          const group = posts[datetime];
          return (
            <Box sx={{marginTop: 4}} key={datetime}>
              <Box mb={2}>
                <Chip label={dayjs(datetime).format('LL')} variant="outlined"/>
              </Box>
              {group.map(item => {
                return (
                  <Bookmark key={item.id} post={item} comments={item.comments}></Bookmark>
                )
              })}
            </Box>
          )
        })
      }
    </Box>
  )
}
