import {useContext, useEffect, useState} from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import {checkValueinArray} from "./utilities";
import GradingIcon from '@mui/icons-material/Grading';
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FeedbackIcon from '@mui/icons-material/Feedback';
import {useAuth0} from "@auth0/auth0-react";
import {AuthenticatedUserContext} from "../context";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {NavBarNotification} from "./notifications";
import AnnouncementIcon from '@mui/icons-material/Announcement';


function NavBarAvatar() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate()
  const {user, isVerified} = useContext(AuthenticatedUserContext)
  const {logout} = useAuth0();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const isAdmin = (
    isVerified &&
    "roles" in user &&
    (
      checkValueinArray(user.roles, "superadmin", "name") ||
      checkValueinArray(user.roles, "admin", "name")
    )
  )
  const isSuperAdmin = (
    isVerified &&
    "roles" in user &&
    (
      checkValueinArray(user.roles, "superadmin", "name")
    )
  )

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
  }, [user])

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <NavBarNotification/>
      <Tooltip onClick={handleOpenUserMenu} title="Open settings">
        <IconButton sx={{p: 0}}>
          <Avatar alt="Current User"
                  src="https://i.pinimg.com/originals/66/25/0e/66250e5096b2e756b1c8184676ab46e9.jpg"/>
        </IconButton>
      </Tooltip>
      <Menu
        PaperProps={{
          style: {
            width: 280,
            maxWidth: 300
          }
        }}
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <ListSubheader component="div">
          <Typography noWrap>Welcome, {user.display_name}</Typography>
        </ListSubheader>
        <MenuItem key="profile" onClick={() => navigate(`/profile/${user.display_name}`)}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small"/>
          </ListItemIcon>
          <Typography noWrap>Profile</Typography>
        </MenuItem>
        <MenuItem key="bookmarks" onClick={() => navigate("/posts/bookmarks")}>
          <ListItemIcon>
            <BookmarkIcon fontSize="small"/>
          </ListItemIcon>
          <Typography noWrap>Bookmarks</Typography>
        </MenuItem>
        {isAdmin ? (
          <div>
            <Divider/>
            <ListSubheader component="div">
              Admin Actions
            </ListSubheader>
            <MenuItem key="dashboard" onClick={() => navigate("/admin/dashboard")}>
              <ListItemIcon>
                <DashboardIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            <MenuItem key="announcements" onClick={() => navigate("/admin/announcements")}>
              <ListItemIcon>
                <AnnouncementIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Announcements</ListItemText>
            </MenuItem>
            <MenuItem key="for-reviews" onClick={() => navigate("/posts/for-reviews")}>
              <ListItemIcon>
                <GradingIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>For review Posts</ListItemText>
            </MenuItem>
            <MenuItem key="inappropriate" onClick={() => navigate("/posts/inappropriates")}>
              <ListItemIcon>
                <FlagIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Inappropriate Posts</ListItemText>
            </MenuItem>
            <MenuItem key="scheduled" onClick={() => navigate("/posts/scheduled")}>
              <ListItemIcon>
                <AccessTimeIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Scheduled Posts</ListItemText>
            </MenuItem>
            <MenuItem key="users" onClick={() => navigate("/admin/users")}>
              <ListItemIcon>
                <GroupIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </MenuItem>
            <MenuItem key="supported_emails" onClick={() => navigate("/admin/notify-emails")}>
              <ListItemIcon>
                <EmailIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Notify Emails</ListItemText>
            </MenuItem>
            <MenuItem key="feedbacks" onClick={() => navigate("/admin/feedbacks")}>
              <ListItemIcon>
                <FeedbackIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Feedbacks</ListItemText>
            </MenuItem>
          </div>
        ) : null}
        {isSuperAdmin ? <MenuItem key="audits" onClick={() => navigate("/admin/audits")}>
          <ListItemIcon>
            <ListAltIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText>Audits</ListItemText>
        </MenuItem> : null}
        <Divider/>
        <MenuItem key="logout" onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>
          <ListItemIcon>
            <LogoutIcon fontSize="small"/>
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}


export function DefaultNavBar() {
  const {user} = useContext(AuthenticatedUserContext)
  const {loginWithRedirect} = useAuth0();
  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              display: {xs: 'none', md: 'flex'},
              color: 'inherit',
              textDecoration: 'none',
              flexGrow: 1,
            }}>
            Anonymed
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            {user ? <NavBarAvatar/> :
              <Button onClick={() => {
                loginWithRedirect()
              }
              } color="inherit">Login</Button>
            }
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export function BottomNavBar(props) {
  return (
    <Box sx={{marginTop: 10}}>
      <Toolbar/>
      <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0}}>
        <Toolbar>
          <IconButton onClick={() => window.location.href = "/"} color="inherit" aria-label="open drawer">
            <HomeIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
