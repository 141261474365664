import {DataGrid} from "@mui/x-data-grid";
import React, {useContext, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Layout from "./layout";
import Toolbar from "@mui/material/Toolbar";
import {AuthenticatedUserContext} from "../context";
import {renderCellExpand} from "./utilities/grids";


function Audits() {
  const [audits, setAudits] = useState([])
  const {firebase_user, isVerified, getBackendToken} = useContext(AuthenticatedUserContext)
  const pulledData = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  const getAudits = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/audits/`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then(response => response.json()).then(data => {
      setAudits(data)
    })
  }

  useEffect(() => {
    if (!!isVerified && !pulledData.current && accessToken) {
      getAudits()
      pulledData.current = true
    }

    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user && !accessToken) {
      setupAccessToken()
    }
  }, [getAudits, isVerified, accessToken])

  const columns = [
    {
      field: 'datetime_created',
      headerName: 'Date Time',
      flex: 1.5,
      renderCell: (params) => {
        return dayjs(params.value).locale("en").toString()
      },
    },
    {
      field: "user_id",
      headerName: "User",
      flex: 1,
    },
    {
      field: "endpoint",
      headerName: "Url",
      flex: 1.5,
      renderCell: renderCellExpand,
    },
    {
      field: "request_method",
      headerName: "Method",
      flex: .5,
    },
    {
      field: "request_body",
      headerName: "Request Body",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "response_status",
      headerName: "Status",
      flex: .5,
    },
    {
      field: "response_body",
      headerName: "Response Body",
      flex: 2,
      renderCell: renderCellExpand,
    }
  ]

  return (
    <Box sx={{height: 800}}>
      <DataGrid
        autoHeight={true}
        rows={audits}
        columns={columns}
      />
    </Box>
  )
}

export function AuditPage() {
  return (
    <Layout>
      <Toolbar/>
      <Audits/>
    </Layout>
  )
}
