import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthenticatedUserContext} from "../context";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import Layout from "./layout";
import Toolbar from "@mui/material/Toolbar";
import {renderCellExpand} from "./utilities/grids";
import dayjs from "dayjs";


function Feedbacks() {
  const [feedbacks, setFeedbacks] = useState([])
  const {firebase_user, isVerified, getBackendToken} = useContext(AuthenticatedUserContext)
  const pulledData = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  const getFeedbacks = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/feedbacks/`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then(response => response.json()).then(data => {
      setFeedbacks(data.feedbacks)
    })
  }

  useEffect(() => {
    if (!!isVerified && !pulledData.current && accessToken) {
      getFeedbacks()
      pulledData.current = true
    }

    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user && !accessToken) {
      setupAccessToken()
    }

  }, [getFeedbacks, isVerified, accessToken])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 2,
      renderCell: renderCellExpand,
    },
    {
      field: "email",
      headerName: "Email",
      flex: .5,
      renderCell: renderCellExpand,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: .5,
      renderCell: renderCellExpand,
    },
    {
      field: "datetime_created",
      headerName: "Posted",
      flex: .5,
      renderCell: (params) => {
        params.value = dayjs(params.value).format("LLL")
        return renderCellExpand(params)
      },
    },
  ]

  return (
    <Box sx={{height: 800}}>
      <DataGrid
        autoHeight={true}
        rows={feedbacks}
        columns={columns}
      />
    </Box>
  )
}

export function FeedbackPage() {
  return (
    <Layout>
      <Toolbar/>
      <Toolbar/>
      <Feedbacks/>
    </Layout>
  )
}
