import {Backdrop, CircularProgress} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AuthenticatedUserContext} from "../../context";


export function useFetch() {
  const {firebase_user, getBackendToken} = useContext(AuthenticatedUserContext);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (firebase_user) {
        setAccessToken(await getBackendToken());
      }
    };
    fetchToken();
  }, [firebase_user]);

  return accessToken;
}


export function checkValueinArray(array, value, property_name) {
  return array.some(el => el[property_name] === value);
}

export function LoadingScreen() {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={true}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
}

export function validateEmailBackend(email, successCallback, errorCallback) {
  if (!email || email === '') {
    return true
  }

  const url = `${process.env.REACT_APP_API_URL}/api/v2/auth/signup/emails/validation/`
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({email: email})
  }

  fetch(url, options).then(response => {
    if (response.status === 200) {
      successCallback()
    } else if (response.status === 400) {
      return response.json().then(data => {
        errorCallback(data)
      })
    } else {
      throw new Error(response.statusText)
    }
  }).catch(error => {
    console.log(error)
    return false
  })
}
