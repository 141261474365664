import { Chip, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useFetch } from "../utilities";
import { AuthenticatedUserContext } from "../../context";

export function TopicSubscription() {
  const [topics, setTopics] = useState([]);
  const { user } = useContext(AuthenticatedUserContext);
  const [subs, setSubs] = useState([]);
  const accessToken = useFetch();

  useEffect(() => {
    if (user) {
      setSubs(user.pubnub_subscriptions.split(","));
    }
  }, [user]);

  useEffect(() => {}, [subs]);

  useEffect(() => {
    const getTopics = () => {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/topics/`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(url, options)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setTopics(data);
        });
    };

    getTopics();
  }, []);

  const handleChipClick = (targetID) => {
    if (!accessToken) {
      return false;
    }

    const isSubscribed = subs.some((item) => item === `topic.${targetID}`);
    let action = "subscribe";
    if (isSubscribed) {
      action = "unsubscribe";
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/topics/${targetID}/${action}/`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        alert(
          data.message + ". This page will reload to apply the new changes.",
        );
        window.location.reload();
      });
  };

  return (
    <Box>
      {topics &&
        topics.map((topic) => (
          <Chip
            key={topic.id}
            style={{ marginTop: 5, marginLeft: 3, marginRight: 3 }}
            label={topic.name}
          />
        ))}
    </Box>
  );
}

export function Topics(props) {
  const [topics, setTopics] = useState([]);
  const { selectedTopic, setTopic } = props;

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/topics/`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setTopics(data);
      });
  };
  return (
    <Grid container sx={{ marginTop: 2 }} rowSpacing={1} columnSpacing={1}>
      <Grid item>
        <Chip
          color={!selectedTopic ? "primary" : "default"}
          onClick={() => setTopic(null)}
          label={"All"}
          size={"small"}
        />
      </Grid>
      {topics.map((topic) => (
        <Grid item key={topic.id}>
          <Chip
            color={topic.id === selectedTopic ? "primary" : "default"}
            onClick={() => setTopic(topic.id)}
            key={topic.id}
            label={topic.name}
            size={"small"}
          />
        </Grid>
      ))}
    </Grid>
  );
}
