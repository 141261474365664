import {NotifyDomainTable} from "./notifyDomain";
import Layout from "../layout";
import Toolbar from "@mui/material/Toolbar";

export function NotifyEmailsTablePage() {
  return (
    <Layout>
      <Toolbar/>
      <h1>Emails</h1>
      <NotifyDomainTable/>
    </Layout>
  );
}
