import React, {useEffect, useState} from "react";
import {useGridApiContext} from "@mui/x-data-grid";
import {AuthenticatedUserContext} from "../../context";
import {logout} from "../auths";
import {useNavigate} from "react-router-dom";
import {BadgesAutocomplete, RolesAutocomplete} from "./autocomplete";


export function RolesForm(props) {
  const [selectedRoles, setSelectedRoles] = React.useState(props.selectedRoles || []);
  const [accessToken, setAccessToken] = useState(null)

  const {firebase_user, getBackendToken} = React.useContext(AuthenticatedUserContext);
  const apiRef = useGridApiContext();
  const navigate = useNavigate()
  const grantRoles = (selectedRoles) => {
    const data = {
      user_id: props.user_id,
      roles: selectedRoles.map(role => role.id)
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/roles/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data)
    }
    fetch(url, options).then(response => {
      if (response.status === 401) {
        console.log("Unauthorized");
        logout(navigate('/app/login'))
      } else if (response.status === 200) {
        return response.json()
      } else {
        const message = "Cannot grant role to this user."
        alert(message)
        throw new Error(message)
      }
    }).then(data => {
      setSelectedRoles(selectedRoles);
      apiRef.current.setEditCellValue({
        id: props.updateCellParams.id,
        field: props.updateCellParams.field,
        value: data.roles
      })
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user) {
      setupAccessToken()
    }

  }, [firebase_user])

  return (
    <RolesAutocomplete
      sx={{border: 0}}
      id="id_roles"
      name="roles"
      value={selectedRoles}
      onChange={(e, selectedRoles) => {
        grantRoles(selectedRoles);
      }}
    />
  )
}


export function BadgesForm(props) {
  const [selectedBadges, setSelectedBadges] = React.useState(props.selectedBadges || []);
  const [accessToken, setAccessToken] = useState(null)

  const {firebase_user, getBackendToken} = React.useContext(AuthenticatedUserContext);
  const apiRef = useGridApiContext();
  const addBadges = (selectedBadges) => {
    const data = {
      user_id: props.user_id,
      badges: selectedBadges.map(badge => badge.id)
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/badges/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data)
    }
    fetch(url, options).then(response => {
      if (response.status === 401) {
        console.log("Unauthorized");
      } else if (response.status === 200) {
        return response.json()
      } else {
        const message = "Cannot add badge to this user."
        alert(message)
        throw new Error(message)
      }
    }).then(data => {
      setSelectedBadges(selectedBadges);
      apiRef.current.setEditCellValue({
        id: props.updateCellParams.id,
        field: props.updateCellParams.field,
        value: data.badges
      })
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user) {
      setupAccessToken()
    }

  }, [firebase_user])
  return (
    <BadgesAutocomplete
      sx={{border: 0}}
      id="id_badges"
      name="badges"
      value={selectedBadges}
      onChange={(e, selectedBadges) => {
        addBadges(selectedBadges);
      }}
    />
  )
}
