import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {EditPostForm} from "./form";
import {PostCardActions, PostCardHeader, PostHeaderActions} from "./item";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import {postTextStyling} from "./utilities";
import {AuthenticatedUserContext} from "../../context";
import withSnackbar from "../snackbar";


export function CommentCardContent(props) {
  const {text, datetime_posted, highLightedTexts} = props
  const datetime = dayjs(datetime_posted).fromNow();

  useEffect(() => {
  }, [datetime_posted])

  return (
    <CardContent sx={{paddingY: 0}}>
      {datetime_posted ? (
        <Box>
          <Typography sx={{marginY: 0, fontSize: 10}}>{datetime}</Typography>
        </Box>
      ) : null}
      <Highlighter
        style={postTextStyling}
        searchWords={highLightedTexts ? highLightedTexts.split(" ") : []}
        textToHighlight={text}
      />
    </CardContent>
  )
}


export function CommentEditForm(props) {
  const {closeForm, handleEditSuccessSubmit, post} = props
  return (
    <EditPostForm closeForm={closeForm} successSubmit={handleEditSuccessSubmit} {...post} />
  )
}


function BaseComment(props) {
  const [comment, setComment] = useState({
    id: props.post.id,
    title: props.post.title || '',
    submitter: props.post.submitter,
    text: props.post.text,
    up_vote_count: props.post.up_vote_count,
    down_vote_count: props.post.down_vote_count,
    level: props.post.level,
    parent_id: props.post.parent_id,
    status: props.post.status === 'public' ? null : props.post.status,
    datetime_posted: props.post.datetime_posted,
    votes: props.post.votes || [],
    topic: props.post.topic,
    bookmarks: props.post.bookmarks || [],
    comments_count: props.post.comments_count,
    isOP: props.post.is_op,
    parent_datetime_posted: props.post.parent_datetime_posted,
    tags: props.post.tags,
  })

  const [levelLimit, setLevelLimit] = useState(props.levelLimit)
  const [showDetails, setShowDetails] = useState(true)
  const [showComments, setShowComments] = useState(true)
  const [comments, setComments] = useState(props.post.comments || [])
  const {showFuture} = props
  const [accessToken, setAccessToken] = useState(null)
  const {firebase_user, getBackendToken} = useContext(AuthenticatedUserContext)

  const getComments = (postID, manualShowFuture) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${postID || comment.id}/comments/?show_future=${manualShowFuture || showFuture}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      }
    }
    fetch(url, options).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            setComments(data.items)
            setLevelLimit(data.level_limit)
          })
        } else {
          throw new Error('Failed to get comments');
        }
      }
    ).catch(err => alert(err))
  }

  useEffect(() => {
    setLevelLimit(props.levelLimit)
  }, [props.levelLimit])

  useEffect(() => {
    async function setUpToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user) {
      setUpToken()
    }
  }, [firebase_user])

  useEffect(() => {
  }, [comments])

  useEffect(() => {
    setComment({
      id: props.post.id,
      title: props.post.title || '',
      submitter: props.post.submitter,
      text: props.post.text,
      up_vote_count: props.post.up_vote_count,
      down_vote_count: props.post.down_vote_count,
      level: props.post.level,
      parent_id: props.post.parent_id,
      status: props.post.status === 'public' ? null : props.post.status,
      datetime_posted: props.post.datetime_posted,
      votes: props.post.votes || [],
      topic: props.post.topic,
      bookmarks: props.post.bookmarks || [],
      comments_count: props.post.comments_count,
      isOP: props.post.is_op,
      parent_datetime_posted: props.post.parent_datetime_posted,
      tags: props.post.tags,
    })
  }, [props.post])

  const editSuccessCallback = (data) => {
    const newComment = data.item
    if (newComment.status === "review") {
      alert(`Your post is for review. It will be visible after it is approved by the admin.`)
    }

    setComment({
      ...comment,
      id: newComment.id,
      title: newComment.title,
      submitter: newComment.submitter,
      text: newComment.text,
      up_vote_count: newComment.up_vote_count,
      down_vote_count: newComment.down_vote_count,
      level: newComment.level,
      parent_id: newComment.parent_id,
      status: newComment.status === 'public' ? null : newComment.status,
      datetime_posted: newComment.datetime_posted,
      topic: newComment.topic,
      topic_id: newComment.topic.id,
      isOP: newComment.is_op,
      tags: newComment.tags,
    })
    setShowDetails(true)
    if (!!data.message) {
      props.snackbarShowMessage(data.message)
    }
    if (dayjs(newComment.datetime_posted).isAfter(dayjs())) {
      getComments(null, true)
    }
  }
  return (
    <Card sx={{
      marginTop: 1,
      padding: 0,
      borderColor: 'grey.500',
      marginBottom: 0,
      marginLeft: comment.level > 0 ? 1 : 0,
      borderLeft: comment.level > 0 ? 1 : 0,
    }} elevation={0}>
      {showDetails ? (
        <CommentDetail
          comment={comment}
          setComment={setComment}
          comments={comments}
          setComments={setComments}
          showComments={showComments}
          setShowDetails={setShowDetails}
          setShowComments={setShowComments}
          levelLimit={levelLimit}
          showFuture={showFuture}
        />
      ) : (
        <CommentEditForm
          closeForm={() => setShowDetails(true)}
          handleEditSuccessSubmit={editSuccessCallback}
          post={comment}/>
      )}
      <Box>
        {showComments && comments.length > 0 ?
          <Comments comments={comments}
                    showFuture={showFuture}
                    parentDatetimePosted={comment.datetime_posted}
                    levelLimit={levelLimit}
          /> : null}
      </Box>
    </Card>
  )
}


export function CommentDetail(props) {
  const {
    comment,
    setComment,
    comments,
    setComments,
    setShowDetails,
    showComments,
    setShowComments,
    levelLimit,
    showFuture,
  } = props

  const [newReply, setNewReply] = useState([])
  const {firebase_user} = useContext(AuthenticatedUserContext)

  const repliesEl = newReply.length > 0 && newReply.map((reply) => (
    <Comment key={reply.id} post={reply}/>
  ))

  useEffect(() => {
  }, [comment])

  const deleteCallback = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${comment.id}/`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${firebase_user.accessToken}`,
      }
    }
    fetch(url, options).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            setComment(data)
          })
        } else {
          throw new Error('Failed to delete comment.');
        }
      }
    ).catch(err => alert(err))
  }
  return (
    <>
      <PostCardHeader
        submitter={comment.submitter}
        isOP={comment.isOP}
        datetime_posted={comment.datetime_posted}
        action={
          <PostHeaderActions
            id={comment.id}
            bookmarks={comment.bookmarks}
            submitter_id={comment.submitter.id}
            bookmarkCallback={(newBookmark) => {
              setComment({
                ...comment,
                bookmarks: [newBookmark, ...comment.bookmarks]
              })
            }}
            unbookmarkCallback={() => {
              setComment({
                ...comment,
                bookmarks: comment.bookmarks.filter(bookmark => bookmark.post_id !== comment.id)
              })
            }}
            handleEditCallback={() => {
              setShowDetails(false)
            }}
            handleDeleteCallback={deleteCallback}
          />}
      />
      <CommentCardContent datetime_posted={comment.datetime_posted} text={comment.text}/>
      <PostCardActions
        id={comment.id}
        votes={comment.votes}
        level={comment.level}
        tags={comment.tags}
        up_vote_count={comment.up_vote_count}
        down_vote_count={comment.down_vote_count}
        comments_count={comment.comments_count}
        showComments={showComments && levelLimit !== comment.level}
        setShowComments={setShowComments}
        successVoteCallback={data => {
          setComment(data)
        }}
        levelLimit={levelLimit}
        setComments={(newComment) => {
          setComments([newComment, ...comments])
        }}
        setNewReply={(data) => {
          setNewReply([data, ...newReply])
        }}
        showFuture={showFuture}
        parent_datetime_posted={comment.parent_datetime_posted}
      />
      {newReply.length > 0 && !showComments ? <Box>{repliesEl}</Box> : null}
    </>
  )
}


export function Comments(props) {
  const {comments, parentDatetimePosted, levelLimit, showFuture} = props
  useEffect(() => {
  }, [comments])
  return (
    comments.length > 0 ? comments.map((comment) => (
      <Comment key={comment.id} levelLimit={levelLimit} showFuture={showFuture}
               post={{...comment, parent_datetime_posted: parentDatetimePosted}}/>
    )) : null
  )
}

export const Comment = withSnackbar(BaseComment);
