import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Avatar,
  CardActions,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Popover,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { AuthenticatedUserContext } from "../../context";
import { checkValueinArray } from "../utilities";
import {
  TagsAutocomplete,
  TopicAutocomplete,
  UserAutocomplete,
} from "../../autocomplete";
import withSnackbar from "../snackbar";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import duration from "dayjs/plugin/duration";
import InfoIcon from "@mui/icons-material/Info";

class PostFormBase extends React.Component {
  static contextType = AuthenticatedUserContext;

  constructor(props) {
    super(props);

    this.state = {
      post: {
        title: props.title || "",
        text: props.text || "",
        up_vote_count: props.up_vote_count || 0,
        down_vote_count: props.down_vote_count || 0,
        submitter_id: props.submitter_id || null,
        submitter: props.submitter || {},
        level: props.level || 0,
        parent_id: !!props.parent_id ? props.parent_id : 0,
        datetime_posted: props.datetime_posted || null,
        topic: props.topic || {},
        topic_id: props.topic ? props.topic.id : null,
        parent_datetime_posted: props.parent_datetime_posted || null,
        tags_id: !!props.tags_id ? props.tags_id : [],
      },
      submitLoading: false,
      error: null,
      valid: false,
      showFuture: props.showFuture,
      accessToken: null,
    };
  }

  async componentDidMount() {
    if (this.context.firebase_user) {
      const token = await this.context.getBackendToken();
      this.setState({
        accessToken: token,
      });
    }
  }

  showAuthorsOptions = (user) => {
    if (!user) {
      return false;
    }

    return this.userIsSystemUser(user);
  };

  userIsSystemUser = (user) => {
    return (
      "roles" in user &&
      (checkValueinArray(user.roles, "superadmin", "name") ||
        checkValueinArray(user.roles, "admin", "name") ||
        checkValueinArray(user.roles, "supervised_content_manager", "name") ||
        checkValueinArray(user.roles, "unsupervised_content_manager", "name") ||
        checkValueinArray(user.roles, "moderator", "name"))
    );
  };

  authorUrl = (user) => {
    if (!this.userIsSystemUser(user)) {
      return `${process.env.REACT_APP_API_URL}/api/v1/profiles/`;
    }
  };

  handleReplySendIcon() {
    const showAuthorsOptions = this.showAuthorsOptions(this.context.user);
    let enableIcon = true;
    const sameText = this.state.post.text === this.props.text;

    if (this.state.post.text.length < 1) {
      enableIcon = false;
    }

    if (showAuthorsOptions) {
      if (!this.state.post.submitter_id) {
        enableIcon = false;
      }

      if (
        this.state.post.up_vote_count < 0 ||
        this.state.post.up_vote_count == null
      ) {
        enableIcon = false;
      }

      if (
        this.state.post.down_vote_count < 0 ||
        this.state.post.down_vote_count == null
      ) {
        enableIcon = false;
      }

      const sameUpvoteCount =
        this.state.post.up_vote_count === this.props.up_vote_count;
      const sameDownvoteCount =
        this.state.post.down_vote_count === this.props.down_vote_count;
      const sameAuthor = this.state.post.author === this.props.author;

      if (sameText && sameAuthor && sameDownvoteCount && sameUpvoteCount) {
        enableIcon = false;
      }
    } else {
      if (sameText) {
        enableIcon = false;
      }
    }

    return enableIcon;
  }

  handleSubmit = () => {
    const { user } = this.context;
    const url = `${process.env.REACT_APP_API_URL}/api/v1/${this.userIsSystemUser(user) ? "admin/" : ""}posts/`;
    if (!this.handleReplySendIcon()) {
      return;
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accessToken}`,
      },
      body: JSON.stringify(this.state.post), // body data type must match "Content-Type" header
    };

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          alert("Unauthorized. Please login again.");
        } else if (response.status === 400) {
          return response.json().then((data) => {
            this.setState({
              error: data.detail.message,
              valid: false,
              submitLoading: false,
            });
          });
        } else {
          console.log("Error");
        }
      })
      .then((data) => {
        this.setState({ valid: true });
        this.props.successSubmit(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateTimePostedChange = (newValue) => {
    this.setState({
      post: {
        ...this.state.post,
        datetime_posted: newValue,
      },
    });
  };

  render() {
    const { user, isVerified } = this.context;
    const datetimeValue = this.state.showFuture
      ? this.state.post.datetime_posted
      : null;
    const parentDatetimeValue =
      this.state.post.parent_id && this.state.showFuture
        ? this.state.post.parent_datetime_posted
        : null;
    return (
      <Card elevation={0} sx={{ minWidth: 200, paddingY: 0, marginY: 1 }}>
        <form method="post">
          <CardContent>
            {this.state.error ? (
              <Alert severity="error">{this.state.error}</Alert>
            ) : null}
            <FormControl fullWidth>
              {this.state.post.parent_id === 0 ||
              this.state.post.parent_id === null ? (
                <>
                  <TagsAutocomplete
                    sx={{ marginBottom: 1 }}
                    name={"tag"}
                    value={this.props.tags_id}
                    onChange={(e, value) => {
                      let tags = [];
                      if (value) {
                        tags = value.map((a) => a.id);
                      }
                      this.setState({
                        post: {
                          ...this.state.post,
                          tags_id: tags,
                        },
                      });
                    }}
                  />
                  <TopicAutocomplete
                    sx={{ marginBottom: 1 }}
                    id="id_topic"
                    name="topic"
                    value={this.state.post.topic}
                    inputValue={this.state.post.topic.name}
                    onChange={(e, value) => {
                      let topic = this.state.post.topic;
                      if (value) {
                        topic = value;
                      }
                      this.setState({
                        post: {
                          ...this.state.post,
                          topic: topic,
                          topic_id: topic.id,
                        },
                      });
                    }}
                  />
                  <TextField
                    sx={{ marginY: 1 }}
                    required
                    fullWidth
                    id="id_title"
                    label="Title"
                    name="title"
                    autoFocus
                    placeholder="Write your title here"
                    value={this.state.post.title}
                    onChange={(e) => {
                      this.setState({
                        post: {
                          ...this.state.post,
                          title: e.target.value,
                        },
                      });
                    }}
                  />
                </>
              ) : null}
              <TextField
                sx={{ marginY: 1 }}
                multiline
                rows={4}
                required
                fullWidth
                id="id_text"
                label="Post Text"
                name="text"
                placeholder="What are you thinking..."
                value={this.state.post.text}
                onChange={(e) => {
                  this.setState({
                    post: {
                      ...this.state.post,
                      text: e.target.value,
                    },
                  });
                }}
              />
              <Grid container spacing={1}>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <UserAutocomplete
                    url={this.authorUrl(user)}
                    sx={{ minWidth: 200, width: "100%" }}
                    id="id_submitter"
                    name="submitter"
                    value={this.state.post.submitter}
                    inputValue={this.state.post.submitter.display_name}
                    onChange={(e, value) => {
                      let submitter = this.state.post.submitter;
                      if (value) {
                        submitter = value;
                      }
                      this.setState({
                        post: {
                          ...this.state.post,
                          submitter: submitter,
                          submitter_id: submitter.id,
                        },
                      });
                    }}
                  />
                </Grid>
                {isVerified && this.showAuthorsOptions(user) ? (
                  <>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="id_up_vote_count"
                        label="Up Vote Count"
                        value={this.state.post.up_vote_count}
                        onChange={(e) =>
                          this.setState({
                            post: {
                              ...this.state.post,
                              up_vote_count: e.target.value,
                            },
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="id_down_vote_count"
                        label="Down Vote Count"
                        value={this.state.post.down_vote_count}
                        onChange={(e) =>
                          this.setState({
                            post: {
                              ...this.state.post,
                              down_vote_count: e.target.value,
                            },
                          })
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </FormControl>
          </CardContent>
          {isVerified && this.showAuthorsOptions(user) ? (
            <DateTimeOptions
              datetimeValue={datetimeValue}
              parentDatetimeValue={parentDatetimeValue}
              handleDateTimeChange={this.handleDateTimePostedChange}
            />
          ) : null}
          <CardActions>
            {this.state.submitLoading ? (
              <LoadingButton
                loading
                sx={{ marginLeft: "auto" }}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="outlined"
              >
                Post
              </LoadingButton>
            ) : (
              <Button
                sx={{ marginLeft: "auto" }}
                variant="outlined"
                disabled={!this.handleReplySendIcon()}
                endIcon={
                  <SendIcon
                    color={this.handleReplySendIcon() ? "success" : "disabled"}
                  />
                }
                onClick={() => {
                  this.setState({ submitLoading: true });
                  this.handleSubmit();
                }}
                aria-label="save"
              >
                <Typography>Post</Typography>
              </Button>
            )}
          </CardActions>
        </form>
      </Card>
    );
  }
}

class EditPostFormBase extends React.Component {
  static contextType = AuthenticatedUserContext;

  constructor(props) {
    super(props);
    this.state = {
      post: {
        id: props.id,
        submitter: props.submitter,
        submitter_id: props.submitter.id,
        text: props.text,
        title: props.title,
        up_vote_count: props.up_vote_count,
        down_vote_count: props.down_vote_count,
        level: props.level,
        parent_id: props.parent_id,
        status: props.status === "public" ? null : props.status,
        datetime_posted: props.datetime_posted,
        topic: props.topic || {},
        topic_id: props.topic ? props.topic.id : null,
        tags: props.tags || null,
        tags_id: props.tags ? props.tags.map((a) => a.id) : [],
      },
      submitLoading: false,
      error: null,
      valid: false,
    };
  }

  async componentDidMount() {
    if (this.context.firebase_user) {
      const token = await this.context.getBackendToken();
      this.setState({
        accessToken: token,
      });
    }
  }

  showAuthorsOptions = (user) => {
    if (!user) {
      return false;
    }

    return this.userIsSystemUser(user);
  };

  userIsSystemUser = (user) => {
    return (
      "roles" in user &&
      (checkValueinArray(user.roles, "superadmin", "name") ||
        checkValueinArray(user.roles, "admin", "name") ||
        checkValueinArray(user.roles, "supervised_content_manager", "name") ||
        checkValueinArray(user.roles, "unsupervised_content_manager", "name") ||
        checkValueinArray(user.roles, "moderator", "name"))
    );
  };

  handleReplySendIcon() {
    const showAuthorsOptions = this.showAuthorsOptions(this.context.user);
    let enableIcon = true;
    const sameText = this.state.post.text === this.props.text;
    const sameTitle =
      this.state.post.title === this.props.title &&
      this.state.post.parent_id === null;

    if (this.state.post.text.length < 1) {
      enableIcon = false;
    }

    if (
      this.state.post.title.length < 1 &&
      this.state.post.parent_id === null
    ) {
      enableIcon = false;
    }

    const isParent =
      this.state.post.parent_id === 0 || this.state.post.parent_id === null;
    if (showAuthorsOptions) {
      if (!this.state.post.submitter_id) {
        enableIcon = false;
      }

      if (
        this.state.post.up_vote_count < 0 ||
        this.state.post.up_vote_count == null
      ) {
        enableIcon = false;
      }

      if (
        this.state.post.down_vote_count < 0 ||
        this.state.post.down_vote_count == null
      ) {
        enableIcon = false;
      }

      const sameUpvoteCount =
        this.state.post.up_vote_count === this.props.up_vote_count;
      const sameDownvoteCount =
        this.state.post.down_vote_count === this.props.down_vote_count;
      const sameAuthor = this.state.post.submitter === this.props.submitter;
      const sameDate =
        this.state.post.datetime_posted === this.props.datetime_posted;
      let sameTopic = this.state.post.topic === this.props.topic;

      if (!isParent) {
        sameTopic = true;
      }
      if (
        sameText &&
        sameAuthor &&
        sameDownvoteCount &&
        sameUpvoteCount &&
        sameDate &&
        sameTopic &&
        sameTitle
      ) {
        enableIcon = false;
      }
    } else {
      let sameTopic = this.state.post.topic === this.props.topic;
      if (!isParent) {
        sameTopic = true;
      }
      const noChanges =
        this.state.post.parent_id !== null
          ? sameText && sameTopic
          : sameText && sameTopic && sameTitle;
      if (noChanges) {
        enableIcon = false;
      }
    }

    return enableIcon;
  }

  handleSubmit = () => {
    const { user } = this.context;

    const url = `${process.env.REACT_APP_API_URL}/api/v1/${this.userIsSystemUser(user) ? "admin/" : ""}posts/${this.state.post.id}/`;
    if (!this.state.post.text) {
      return;
    }
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accessToken}`,
      },
      body: JSON.stringify(this.state.post), // body data type must match "Content-Type" header
    };

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          alert("Unauthorized. Please login again.");
        } else if (response.status === 400) {
          return response.json().then((data) => {
            this.setState({
              error: data.detail.message,
              valid: false,
              submitLoading: false,
            });
          });
        } else {
          console.log("Error");
        }
      })
      .then((data) => {
        this.setState({ valid: true });
        this.props.successSubmit(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateTimePostedChange = (newValue) => {
    this.setState({
      post: {
        ...this.state.post,
        datetime_posted: newValue,
      },
    });
  };

  render() {
    const { user, isVerified } = this.context;
    return (
      <>
        {this.state.error ? (
          <Alert severity="error">{this.state.error}</Alert>
        ) : null}
        <CardHeader
          sx={{ paddingY: 0 }}
          avatar={
            <Avatar src="https://i.pinimg.com/originals/66/25/0e/66250e5096b2e756b1c8184676ab46e9.jpg" />
          }
          title={
            isVerified && this.showAuthorsOptions(user) ? (
              <Grid alignItems="center" container spacing={1}>
                <Grid item lg={4} xl={3} md={6} sm={12}>
                  <UserAutocomplete
                    sx={{ marginY: 1, width: 300 }}
                    id="id_submitter"
                    name="submitter"
                    value={this.state.post.submitter}
                    inputValue={this.state.post.submitter.display_name}
                    onChange={(e, value) => {
                      let submitter = this.state.post.submitter;
                      if (value) {
                        submitter = value;
                      }
                      this.setState({
                        post: {
                          ...this.state.post,
                          submitter: submitter,
                          submitter_id: submitter.id,
                        },
                      });
                    }}
                  />
                </Grid>
                {this.state.post.parent_id === 0 ||
                this.state.post.parent_id === null ? (
                  <Grid item lg={4} xl={3} md={6} sm={12}>
                    <TopicAutocomplete
                      sx={{ width: 300 }}
                      id="id_topic"
                      name="topic"
                      value={this.props.topic}
                      inputValue={this.state.post.topic.name}
                      onChange={(e, value) => {
                        let topic = this.state.post.topic;
                        if (value) {
                          topic = value;
                        }
                        this.setState({
                          post: {
                            ...this.state.post,
                            topic: topic,
                            topic_id: topic.id,
                          },
                        });
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <Typography sx={{ fontSize: 20 }}>
                {this.state.post.submitter.display_name}
              </Typography>
            )
          }
        />
        <CardContent sx={{ p: 1 }}>
          <TagsAutocomplete
            sx={{ marginBottom: 1 }}
            name={"tag"}
            value={this.state.post.tags}
            onChange={(e, value) => {
              let tags = this.state.tags;
              let tags_id = this.state.tags_id;
              if (value) {
                tags_id = value.map((a) => a.id);
                tags = value;
              }
              this.setState({
                post: {
                  ...this.state.post,
                  tags_id: tags_id,
                  tags: tags,
                },
              });
            }}
          />
          {this.state.post.parent_id === 0 ||
          this.state.post.parent_id === null ? (
            <TextField
              sx={{ marginY: 1 }}
              required
              fullWidth
              id="id_title"
              label="Title"
              name="title"
              autoFocus
              placeholder="Write your title here"
              value={this.state.post.title}
              onChange={(e) => {
                this.setState({
                  post: {
                    ...this.state.post,
                    title: e.target.value,
                  },
                });
              }}
            />
          ) : null}
          <TextField
            sx={{ m: 0, p: 0 }}
            fullWidth
            multiline
            rows={4}
            required
            id="id_text"
            name="text"
            placeholder="What are you thinking..."
            // variant="standard"
            value={this.state.post.text}
            onChange={(e) => {
              this.setState({
                post: {
                  ...this.state.post,
                  text: e.target.value,
                },
              });
            }}
            InputProps={{
              style: {
                padding: 3,
                paddingLeft: 10,
              },
            }}
          />
        </CardContent>
        <CardActions>
          {isVerified && this.showAuthorsOptions(user) ? (
            <>
              <ArrowUpwardIcon />
              <TextField
                sx={{ marginY: 1, width: 100, padding: 0 }}
                id="id_up_vote_count"
                inputProps={{
                  style: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                  },
                }}
                size="small"
                value={this.state.post.up_vote_count}
                onChange={(e) =>
                  this.setState({
                    post: { ...this.state.post, up_vote_count: e.target.value },
                  })
                }
                type="number"
              />
              <ArrowDownwardIcon />
              <TextField
                sx={{ marginY: 1, width: 100, padding: 0 }}
                inputProps={{
                  style: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                  },
                }}
                size="small"
                id="id_down_vote_count"
                value={this.state.post.down_vote_count}
                onChange={(e) =>
                  this.setState({
                    post: {
                      ...this.state.post,
                      down_vote_count: e.target.value,
                    },
                  })
                }
                type="number"
              />
              <IconButton disabled={true} aria-label="reply">
                <ChatBubbleOutlineIcon />
              </IconButton>
              <IconButton disabled={true} aria-label="flag as inappropriate">
                {this.state.post.status === "inappropriate" ? (
                  <FlagIcon />
                ) : (
                  <FlagOutlinedIcon />
                )}
              </IconButton>
              <IconButton disabled={true}>
                <ShareIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton disabled={true} aria-label="upvote">
                <ArrowUpwardIcon />
                <Typography>{this.state.post.up_vote_count}</Typography>
              </IconButton>
              <IconButton disabled={true} aria-label="downvote">
                <ArrowDownwardIcon />
                <Typography>{this.state.post.down_vote_count}</Typography>
              </IconButton>
              <IconButton disabled={true} aria-label="reply">
                <ChatBubbleOutlineIcon />
              </IconButton>
              <IconButton disabled={true} aria-label="flag as inappropriate">
                {this.state.post.status === "inappropriate" ? (
                  <FlagIcon />
                ) : (
                  <FlagOutlinedIcon />
                )}
              </IconButton>
              <IconButton disabled={true}>
                <ShareIcon />
              </IconButton>
            </>
          )}
        </CardActions>
        {isVerified && this.showAuthorsOptions(user) ? (
          <DateTimeOptions
            datetimeValue={this.state.post.datetime_posted}
            parentDatetimeValue={this.state.post.datetime_posted}
            handleDateTimeChange={this.handleDateTimePostedChange}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{ marginX: 1 }}
            color="error"
            variant="outlined"
            startIcon={<CloseIcon color="error" />}
            onClick={() => this.props.closeForm()}
            aria-label="cancel"
          >
            <Typography>Cancel</Typography>
          </Button>
          {this.state.submitLoading ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<CheckIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              sx={{ marginX: 1 }}
              color={this.handleReplySendIcon() ? "success" : "primary"}
              startIcon={
                <CheckIcon
                  color={this.handleReplySendIcon() ? "success" : "disabled"}
                />
              }
              variant="outlined"
              disabled={!this.handleReplySendIcon()}
              onClick={() => {
                this.setState({ submitLoading: true });
                this.handleSubmit();
              }}
              aria-label="save"
            >
              Save
            </Button>
          )}
        </Box>
      </>
    );
  }
}

function DateTimeOptions(props) {
  const { datetimeValue, parentDatetimeValue, handleDateTimeChange } = props;
  return (
    <Grid alignItems="left" container spacing={1}>
      <Grid item xl={12}>
        <Typography>{"Published Date"}</Typography>
      </Grid>
      <Grid item>
        <CustomDateTimePicker
          datetimeValue={datetimeValue}
          handleDateTimeChange={handleDateTimeChange}
        />
      </Grid>
      <Grid item xl={1}>
        <Divider>OR</Divider>
      </Grid>
      <Grid item>
        <MinsHoursDaysPicker
          datetimeValue={parentDatetimeValue ? parentDatetimeValue : null}
          handleDateTimeChange={handleDateTimeChange}
        />
      </Grid>
    </Grid>
  );
}

function CustomDateTimePicker(props) {
  const { datetimeValue, handleDateTimeChange } = props;
  useEffect(() => {}, [datetimeValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TextField
        size={"small"}
        type="datetime-local"
        value={
          !datetimeValue
            ? dayjs().format("YYYY-MM-DDTHH:mm:ss")
            : dayjs(datetimeValue).format("YYYY-MM-DDTHH:mm:ss")
        }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          const newValue = dayjs(event.target.value).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
          );
          handleDateTimeChange(newValue);
        }}
      />
    </LocalizationProvider>
  );
}

export function MinsHoursDaysPicker(props) {
  const [minuteValue, setMinuteValue] = useState(0);
  const [hourValue, setHourValue] = useState(0);
  const [dayValue, setDayValue] = useState(0);
  const [newDateTimeValue, setNewDateTimeValue] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverId = "popover-id";

  const { datetimeValue, handleDateTimeChange } = props;
  useEffect(() => {
    const format = "YYYY-MM-DDTHH:mm:ss.SSSZ";
    const durationDict = {
      days: dayValue,
      hours: hourValue,
      minutes: minuteValue,
    };
    dayjs.extend(duration);
    const datetime = datetimeValue ? dayjs(datetimeValue) : dayjs();
    const newDateTime = datetime.add(dayjs.duration(durationDict));
    handleDateTimeChange(newDateTime.format(format));
    setNewDateTimeValue(newDateTime.format("LLL"));
  }, [minuteValue, hourValue, dayValue]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box>
        <TextField
          sx={{ width: 80, marginBottom: 1 }}
          id={"minutes-id"}
          type={"number"}
          size={"small"}
          value={minuteValue}
          onChange={(event) => setMinuteValue(event.target.value)}
          InputProps={{
            inputProps: { min: 0, max: 999 },
          }}
          label={"minutes"}
        />
        <TextField
          sx={{ width: 80, marginBottom: 1 }}
          id={"hours-id"}
          type={"number"}
          size={"small"}
          value={hourValue}
          onChange={(event) => setHourValue(event.target.value)}
          InputProps={{
            inputProps: { min: 0, max: 999 },
          }}
          label={"hours"}
        />
        <TextField
          sx={{ width: 80, marginBottom: 1 }}
          id={"days-id"}
          type={"number"}
          size={"small"}
          value={dayValue}
          onChange={(event) => setDayValue(event.target.value)}
          InputProps={{
            inputProps: { min: 0, max: 999 },
          }}
          label={"days"}
        />
        <IconButton
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          aria-owns={open ? popoverId : undefined}
          aria-haspopup="true"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <Typography variant="body1">
        {"from "}
        {datetimeValue ? newDateTimeValue : "now"}
      </Typography>
      <Popover
        id={popoverId}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper>
          <Typography sx={{ p: 2 }}>{newDateTimeValue}</Typography>
        </Paper>
      </Popover>
    </Box>
  );
}

export const PostForm = withSnackbar(PostFormBase);
export const EditPostForm = withSnackbar(EditPostFormBase);
