import {CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import dayjs from "dayjs";
import {AuthenticatedUserContext} from "../../context";
import {useContext, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function PostDailyThreads() {
  const {firebase_user, isVerified, getBackendToken} = useContext(AuthenticatedUserContext);
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(dayjs().startOf('year').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const pulledThreads = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (isVerified && !pulledThreads.current) {
      getDailyThreads()
      pulledThreads.current = true
    }
    setupAccessToken()
  }, [isVerified, firebase_user])

  function getDatesInRange(startDate, endDate) {
    let date = new dayjs(startDate);
    const end = new dayjs(endDate)
    const dates = [];

    while (date <= end) {
      dates.push(date.format('MM-DD'));
      date = date.add(1, 'day');
    }
    return dates;
  }

  const getData = (data) => {
    const dates = getDatesInRange(startDate, endDate)
    const threads = []
    for (const date of dates) {
      const result = data.filter(d => dayjs(d.date).format('MM-DD') === date)
      threads.push({
        date,
        count: result.length === 1 ? result[0].count : null
      })
    }

    return threads
  }

  const getDailyThreads = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/core/metrics/posts/thread-counts/?start_date=${startDate}&end_date=${endDate}`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
    }
    fetch(url, options)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error("Error fetching daily threads")
        }
      })
      .then(data => {
        setData(getData(data.daily_thread_count))
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Box sx={{width: '100%', height: 600}}>
      <Typography sx={{marginX: 2}} variant={"h6"}>Daily Thread for Year {dayjs().year()}</Typography>
      <ResponsiveContainer>
        <LineChart data={data}>
          <Line connectNulls type="monotone" dataKey="count" stroke="#8884d8"/>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
          <XAxis dataKey="date">
            <Label value="Daily Threads" offset={-2} position="insideBottom"/>
          </XAxis>
          <YAxis>
            <Label value="Number of threads" angle={-90} position="insideLeft"/>
          </YAxis>
          <Tooltip/>
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}
