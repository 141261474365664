import {Component} from "react";
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export class RolesAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      selectedRoles: []
    }
  }

  componentDidMount() {
    this.getRoles()
  }

  getRoles() {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/roles/`;
    fetch(url).then(response => response.json())
      .then(data => {
          this.setState({
            roles: data.roles,
          })
        }
      );
  }

  handleTextChange = (value) => {
    if (value.length < 3) {
      return false
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/roles/?search=${value}`;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          roles: res.roles,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <Autocomplete
        sx={this.props.sx}
        multiple
        fullWidth
        id="id_topic"
        helpertext="Start typing the name of role"
        options={this.state.roles}
        value={this.props.value}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={this.props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Start typing role"
            variant="outlined"
            placeholder="Roles"
          />
        )}
      />
    )
  }
}


export class BadgesAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: [],
      selectedBadges: [this.props.selectedBadges]
    }
  }

  componentDidMount() {
    this.getBadges()
  }

  getBadges() {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/badges/`;
    fetch(url).then(response => response.json())
      .then(data => {
          this.setState({
            badges: data,
          })
        }
      );
  }

  handleTextChange = (value) => {
    if (value.length < 3) {
      return false
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/badges/?search=${value}`;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          badges: res,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <Autocomplete
        sx={this.props.sx}
        multiple
        fullWidth
        id="id_badge"
        helpertext="Start typing the name of badge"
        options={this.state.badges}
        value={this.props.value}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={this.props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Start typing badge"
            variant="outlined"
            placeholder="Badges"
          />
        )}
      />
    )
  }
}
