import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function DoximityCallback() {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const state = new URLSearchParams(search).get('state');
  const session_state = new URLSearchParams(search).get('session_state');
  const navigate = useNavigate();


  useEffect(() => {
    const getBadges = () => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "code": code,
          "session_state": session_state,
          "state": state
        })
      }
      const url = `${process.env.REACT_APP_API_URL}/api/v2/auth/doximity/set-badges/`
      fetch(url, options).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((data) => {
        window.location.href = "https://dev.webapp.anonymed.com/doximity_verify";
      })
        .catch(error => {
          console.log(error)
        })
    }
    if (code && state && session_state) {
      getBadges()
    }
  }, [navigate, code, state, session_state]);

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Redirecting to Login page...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
