import React, {useContext, useEffect, useState} from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from "@mui/material/Box";
import {DoximityLink, EmailVerification, UsernameForm} from "./forms";
import Alert from "@mui/material/Alert";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import {AuthenticatedUserContext} from "../../context";
import {useAuth0} from "@auth0/auth0-react";
import {BaseLayout} from "../layout";
import {LoadingScreen} from "../utilities";


export function AuthFlow() {
  return (
    <BaseLayout>
      <Authentication/>
    </BaseLayout>
  );
}


function Authentication(props) {
  const [render, setRender] = useState(false)

  const [doneVerify, setDoneVerify] = React.useState(false);
  const [doneDoximity, setDoneDoximity] = React.useState(false);
  const [doneUsername, setDoneUsername] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState('login');
  const [accessToken, setAccessToken] = React.useState(null);
  const done = (doneVerify && doneDoximity && doneUsername) || currentStep === 'completed'
  const {firebase_user, getBackendToken} = useContext(AuthenticatedUserContext);
  const {isLoading, user, loginWithRedirect} = useAuth0();
  const navigate = useNavigate()
  useEffect(() => {
    async function setupToken() {
      if (!isLoading) {
        setAccessToken(await getBackendToken())
      }
    }

    setupToken()
  }, [isLoading])

  useEffect(() => {
    if (firebase_user) {
      switch (firebase_user.auth_step) {
        case "email_verification":
          setCurrentStep("email_verification")
          break;
        case "doximity":
          setDoneVerify(true)
          setCurrentStep("doximity")
          break;
        case "username":
          setDoneDoximity(true)
          setDoneVerify(true)
          setCurrentStep("username")
          break;
        case "completed":
          setDoneDoximity(true)
          setDoneVerify(true)
          setDoneUsername(true)
          setCurrentStep("completed")
          setTimeout(() => {
            navigate('/')
          }, 2500)
          break;
      }
    } else if (user) {
      setCurrentStep("email_verification")
    }
    setRender(true)
  }, [user, firebase_user])

  const doximitySuccessCallback = () => {
    setDoneDoximity(true)
  }

  const usernameSuccessCallback = (username) => {
    setDoneUsername(true)
    navigate('/')
  }

  const emailVerificationDisplay = () => {
    if (!doneVerify && currentStep === 'email_verification' && user) {
      return (
        <EmailVerification emailVerified={doneVerify}/>
      )
    } else if (doneVerify) {
      return (
        <Box>
          <Button disabled endIcon={<CheckIcon color="success"/>}>
            Email and Password is verified
          </Button>
        </Box>
      )
    }
  }

  const doximityDisplay = () => {
    if (!doneDoximity && currentStep === 'doximity') {
      return (
        <DoximityLink accessToken={accessToken} done={doneDoximity} successCallback={doximitySuccessCallback}/>
      )
    } else if (doneDoximity) {
      return (
        <Box sx={{marginY: 1}}>
          <Button disabled endIcon={<CheckIcon color="success"/>}>
            Connected with Doximity
          </Button>
        </Box>
      )
    }
  }

  const usernameDisplay = () => {
    const doneOthers = (doneVerify && doneDoximity && accessToken && firebase_user)
    if (!doneUsername && currentStep === 'username' && doneOthers) {
      return (
        <UsernameForm
          accessToken={accessToken}
          user={firebase_user}
          badges={firebase_user.badges}
          successCallback={usernameSuccessCallback}/>
      )
    } else if (doneUsername) {

    }
  }

  const formDisplay = () => {
    const emailVerification = emailVerificationDisplay()
    const doximity = doximityDisplay()
    const username = usernameDisplay()
    return (
      <Box>
        {emailVerification}
        {doximity}
        {username}
      </Box>
    )
  }
  return (
    <BaseLayout>
      {!render ? <LoadingScreen/> :
        <Card sx={{minWidth: 275, maxWidth: 400, marginY: 10, marginX: "auto", border: 1}}>
          <form
            method="post"
          >
            <CardContent>
              {!done || currentStep === 'completed' ? formDisplay() : (
                <Alert severity="success">
                  Login successful! Redirecting to home page...
                </Alert>
              )}
            </CardContent>
          </form>
        </Card>}

    </BaseLayout>
  )
}

export default Authentication
