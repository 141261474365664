import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

const withSnackbar = (WrappedComponent) => {
  return (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("Success!");
    const [duration, setDuration] = useState(6000);
    const [severity, setSeverity] = useState("success");
    /** error | warning | info */

    const navigate = useNavigate();
    const showMessage = (message, severity = "success", duration = 6000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
    return (
      <>
        <WrappedComponent
          {...props}
          navigate={navigate}
          snackbarShowMessage={showMessage}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <Alert variant="filled" onClose={handleClose} severity={severity}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          </Alert>
        </Snackbar>
      </>
    );
  };
};

export default withSnackbar;
