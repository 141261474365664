import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthenticatedUserContext } from "../../context";
import { CardActions, Fab, IconButton, styled } from "@mui/material";
import Box from "@mui/material/Box";
import withSnackbar from "../snackbar";
import { PostForm } from "./form";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import { PostCardContent, PostDetail, PostList, ReadOnlyPost } from "./item";
import { useParams } from "react-router-dom";
import { PostSearch } from "./utilities";
import { BookmarkComment } from "./bookmarks";
import { LoadingScreen } from "../utilities";
import dayjs from "dayjs";
import { TopicSubscription } from "../topics";

export function ForReviewPosts() {
  const { firebase_user, isLoggedIn, getBackendToken } = useContext(
    AuthenticatedUserContext,
  );
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken());
    }

    if (firebase_user && isLoggedIn && loading && accessToken) {
      getPosts();
    }
    setupAccessToken();
  }, [isLoggedIn, firebase_user, loading, accessToken]);

  const doAction = (post_id, action_str) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${post_id}/for-reviews/actions/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ action: action_str }),
    };

    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Unauthorized");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setPosts(posts.filter((post) => post.id !== data.id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPosts = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/?status=review`;
    const options = {
      method: "GET",
      headers: {},
    };
    if (!!firebase_user) {
      options.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    fetch(url, options)
      .then((res) => {
        if (res.status === 401) {
          console.log("Unauthorized");
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setPosts(res.posts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const actions = (post_id) => {
    return (
      <CardActions>
        <IconButton
          color="primary"
          sx={{ fontSize: 17 }}
          onClick={() => doAction(post_id, "approve")}
          aria-label="approve"
        >
          <CheckIcon sx={{ fontSize: "inherit" }} />
          <Typography>Accept</Typography>
        </IconButton>
        <IconButton
          color="error"
          sx={{ fontSize: 17 }}
          onClick={() => doAction(post_id, "deny")}
          aria-label="deny"
        >
          <CloseIcon sx={{ fontSize: "inherit" }} />
          <Typography>Deny</Typography>
        </IconButton>
      </CardActions>
    );
  };

  return (
    <Box>
      {posts &&
        posts.map((post) => (
          <ReadOnlyPost
            key={post.id}
            text={post.text}
            title={post.title}
            post={post}
            actions={actions(post.id)}
          />
        ))}
    </Box>
  );
}

export function InappropriatePosts() {
  const { firebase_user, isLoggedIn, getBackendToken } = useContext(
    AuthenticatedUserContext,
  );
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken());
    }

    if (firebase_user && isLoggedIn && loading && accessToken) {
      getPosts();
    }
    setupAccessToken();
  }, [isLoggedIn, firebase_user, loading, accessToken]);

  const getPosts = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/inappropriates/`;
    const options = {
      method: "GET",
      headers: {},
    };
    if (!!firebase_user) {
      options.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    fetch(url, options)
      .then((res) => {
        if (res.status === 401) {
          console.log("Unauthorized");
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setPosts(res.posts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unflag = (post_id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${post_id}/inappropriates/unflag/`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Unauthorized");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setPosts(posts.filter((post) => post.id !== data.id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actions = (post) => {
    return (
      <CardActions>
        <IconButton
          color="error"
          sx={{ fontSize: 17 }}
          aria-label="inappropriate"
        >
          <FlagIcon sx={{ fontSize: "inherit" }} />
          <Typography>{post.votes.length}</Typography>
        </IconButton>
        <IconButton
          color="success"
          sx={{ fontSize: 17 }}
          onClick={() => unflag(post.id)}
          aria-label="revoke"
        >
          <CheckIcon sx={{ fontSize: "inherit" }} />
          <Typography>Unflag</Typography>
        </IconButton>
      </CardActions>
    );
  };

  return (
    <Box>
      {posts &&
        posts.map((post) => (
          <ReadOnlyPost
            key={post.id}
            title={post.inappropriate_title}
            text={post.inappropriate_text}
            post={post}
            actions={actions(post)}
          />
        ))}
    </Box>
  );
}

export function ScheduledPosts() {
  const { firebase_user, isLoggedIn, getBackendToken } = useContext(
    AuthenticatedUserContext,
  );
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken());
    }

    if (firebase_user && isLoggedIn && loading && accessToken) {
      getPosts();
    }
    setupAccessToken();
  }, [isLoggedIn, firebase_user, loading, accessToken]);

  const getPosts = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/scheduled/`;
    const options = {
      method: "GET",
      headers: {},
    };
    if (!!firebase_user) {
      options.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    fetch(url, options)
      .then((res) => {
        if (res.status === 401) {
          console.log("Unauthorized");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      {posts &&
        posts.map((post) => (
          <PostDetail showFuture={true} post={post} key={post.id} />
        ))}
    </Box>
  );
}

function Posts(props) {
  const [posts, setPosts] = useState([]);
  const [showPostForm, setShowPostForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState(null);
  const [topicId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const { firebase_user, getBackendToken, isVerified, user } = useContext(
    AuthenticatedUserContext,
  );
  useEffect(() => {
    async function setUpPosts() {
      setAccessToken(await getBackendToken());
      getPosts();
    }

    if (firebase_user) {
      setUpPosts();
    }
  }, [firebase_user, topicId]);

  useEffect(() => {
    const delayedSearch = setTimeout(getPosts.bind(null, searchKey), 500);
    return () => clearTimeout(delayedSearch);
  }, [searchKey]);

  const getPosts = (value) => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/posts/`;
    if (!!value && value !== "") {
      url = url.concat(`?search=${encodeURI(value.trim())}`);
    }
    if (!!topicId && topicId !== "") {
      url = url.concat(`${url.includes("search") ? "&" : "?"}topic=${topicId}`);
    }
    const options = {
      method: "GET",
      headers: {},
    };
    if (!!accessToken) {
      options.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    fetch(url, options)
      .then((res) => {
        if (res.status === 401) {
          console.log("Unauthorized");
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setPosts(res.posts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearchKeyChange = (value) => {
    setSearchKey(value);
  };

  const handleCreateSuccessSubmit = (data) => {
    if (data.status === "review") {
      props.snackbarShowMessage(
        `Your post is for review. It will be visible after it is approved by the admin.`,
      );
      setShowPostForm(false);
    } else if (dayjs(data.datetime_posted) > dayjs()) {
      props.snackbarShowMessage(
        `You created a scheduled post. It will be visible on ${dayjs(data.datetime_posted).format("LLL")}`,
      );
      setShowPostForm(false);
    } else {
      setPosts([data, ...posts]);
      setShowPostForm(false);
    }
  };

  const StyledFab = styled(Fab)({
    position: "fixed",
    zIndex: 1000000000,
    left: 0,
    bottom: 8,
    right: 0,
    margin: "0 auto",
  });
  const postList = (searchKeyword) => {
    if (!!searchKeyword) {
      return posts.map((item) => (
        <Box key={item.id}>
          <PostList
            cardContent={
              <PostCardContent
                key={item.id}
                highLightedTexts={searchKeyword}
                title={item.title}
                tags={item.tags}
                text={item.text}
              />
            }
            key={item.id}
            post={item}
          />
          {item.comments &&
            item.comments.map((comment) => (
              <BookmarkComment
                key={comment.id}
                highLightedTexts={searchKeyword}
                post={comment}
              />
            ))}
        </Box>
      ));
    } else {
      return posts.map((item) => <PostList key={item.id} post={item} />);
    }
  };

  return (
    <Box>
      <PostSearch
        searchKey={searchKey || ""}
        handleSearchKeyChange={handleSearchKeyChange}
      />
      <TopicSubscription />
      {showPostForm ? (
        <PostForm
          successSubmit={handleCreateSuccessSubmit}
          submitter={user}
          submitter_id={user.id}
        />
      ) : null}
      {loading ? <LoadingScreen /> : postList(searchKey)}
      {isVerified ? (
        <StyledFab
          onClick={() => setShowPostForm(!showPostForm)}
          size="small"
          color="white"
          aria-label="add"
        >
          <AddIcon />
        </StyledFab>
      ) : null}
    </Box>
  );
}

export function UserPosts() {
  const [posts, setPosts] = useState([]);
  const pulledData = useRef(false);
  const { firebase_user, isVerified } = useContext(AuthenticatedUserContext);
  let params = useParams();

  const getPosts = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/users/${params.user_id}/`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${firebase_user.accessToken}`,
      },
    };
    fetch(url, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((data) => {
        console.log(data);
        setPosts(data.posts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isVerified && !pulledData.current) {
      getPosts();
      pulledData.current = true;
    }
  }, [isVerified]);

  return (
    <Box>
      {posts.map((item) => (
        <PostList
          cardContent={<PostCardContent title={item.title} text={item.text} />}
          key={item.id}
          post={item}
        />
      ))}
    </Box>
  );
}

export default withSnackbar(Posts);
