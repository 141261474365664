import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


export function Metric(props) {
  const {title, value} = props
  return (
    <Card sx={{flexGrow: 1, margin: .5, minWidth: 150}}>
      <CardContent>
        <Typography align="center">{title}</Typography>
        <Typography align="center" variant="h2">{value || "--"}</Typography>
      </CardContent>
    </Card>
  )
}

export function UserMetrics(props) {
  const {total, active} = props

  return (
    <Card elevation={0}>
      <CardHeader title="Users"/>
      <CardContent>
        <Box sx={{display: "flex", flexDirection: 'row', flexWrap: "wrap"}}>
          <Metric title="Total" value={total}/>
          <Metric title="Active" value={active}/>
        </Box>
      </CardContent>
    </Card>
  )
}

export function PostMetrics(props) {
  const {total, postLevel} = props
  const postLevelDisplay = Object.keys(postLevel).map(key => {
    const title = "Level " + key
    return (<Metric key={key} title={title} value={postLevel[key]}/>)
  })

  return (
    <Card elevation={0}>
      <CardHeader title="Posts"/>
      <CardContent>
        <Box sx={{display: "flex", flexDirection: 'row', flexWrap: "wrap"}}>
          <Metric key={"total"} title={"Total"} value={total}/>
          {postLevelDisplay}
        </Box>
      </CardContent>
    </Card>
  )
}
