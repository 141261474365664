import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import IconButton from "@mui/material/IconButton";
import { useFetch } from "../utilities";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export function Subscribe(props) {
  const { url, isSubscribed } = props;
  const accessToken = useFetch();

  const handleClick = () => {
    if (!accessToken) {
      return false;
    }

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.reload();
      });
  };
  return (
    <IconButton onClick={handleClick}>
      {!isSubscribed ? <NotificationsNoneIcon /> : <NotificationsActiveIcon />}
    </IconButton>
  );
}
