import Box from "@mui/material/Box";
import React, {useContext, useEffect, useState} from "react";
import {Profile} from "../profiles";
import Layout from "../layout";
import Toolbar from "@mui/material/Toolbar";
import {useNavigate, useParams} from "react-router-dom";
import {AuthenticatedUserContext} from "../../context";
import Button from "@mui/material/Button";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

function User (){
  const {getBackendToken} = useContext(AuthenticatedUserContext);
  const [accessToken, setAccessToken] = useState(null)
  const [profile, setProfile] = useState(null)

  let params = useParams();
  useEffect(() => {
    const setupAccessToken = async () => {
      setAccessToken(await getBackendToken())
    };
    if (!accessToken) {
      setupAccessToken()
    } else {
      getProfile()
    }
  }, [accessToken, params])

  const getProfile = () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/admin/profiles/detail/${params.username}`
    fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        return response.json().then((data) => {throw data})
      }
    })
      .then(data => {
        setProfile(data)
      })
      .catch(error => {
        alert("No username")
      });
  }
  const baseAPI = `${process.env.REACT_APP_API_URL}/api/v1/admin/profiles/`
  const setProfileURL = `${baseAPI}current/`
  return (
    <Box>
      {!!profile && (
        <Box>
          <Profile deleteAPI={baseAPI} fetchAPI={`${baseAPI}${profile.id}/`} createNewProfile={baseAPI} setProfileURL={`${setProfileURL}${profile.id}/`} data={{profile_id: profile.id}}/>
        </Box>
      )}
    </Box>
  )
}


export function UserDetailPage(){
  const navigate = useNavigate();

  return(
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <Box sx={{marginX: 2}}>
        <Button onClick={() => {navigate("/admin/users/")}} startIcon={<ArrowLeftIcon />} sx={{marginBottom: 2}}>Back to Users</Button>
        <User/>
      </Box>
    </Layout>
  )
}