import {PostMetrics, UserMetrics} from "./metrics";
import Toolbar from "@mui/material/Toolbar";
import Layout from "../layout";
import {Container} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthenticatedUserContext} from "../../context";
import Typography from "@mui/material/Typography";
import {PostDailyThreads} from "./charts";

export function DashBoardMain() {
  const [userMetrics, setUserMetrics] = useState({
    total: null
  })
  const [postMetrics, setPostMetrics] = useState({
    threadCount: null,
    postLevel: {},
  })
  const {isVerified, firebase_user, getBackendToken} = useContext(AuthenticatedUserContext)
  const pulledMetrics = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (isVerified && !pulledMetrics.current) {
      getMetrics()
      pulledMetrics.current = true
    }
    setupAccessToken()
  }, [isVerified])

  const getMetrics = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/core/dashboard/metrics/`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error("Error getting metrics")
        }
      })
      .then(data => {
        setPostMetrics({
          threadCount: data.posts.total_threads,
          postLevel: data.posts.posts_per_level,
        })
        setUserMetrics({
          total: data.users.total
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Container sx={{paddingTop: 3, marginBottom: 5}}>
      <Typography variant="h4">{"Dashboard"}</Typography>
      <UserMetrics total={userMetrics.total}/>
      <PostMetrics total={postMetrics.threadCount} postLevel={postMetrics.postLevel}/>
      <PostDailyThreads/>
    </Container>
  )
}

export function DashboardPage() {
  return (
    <Layout>
      <Toolbar/>
      <DashBoardMain/>
    </Layout>
  )
}
