import { usePubNub } from "pubnub-react";
import React, { useContext, useEffect, useState } from "react";
import { AuthenticatedUserContext } from "../context";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import {
  Badge,
  IconButton,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Tooltip,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

function NotificationIconNav(props) {
  const { trigger } = props;
  const [newCount, addNewCount] = useState();
  const [anchorElNotif, setAnchorElNotif] = useState(null);
  const { firebase_user, getBackendToken } = useContext(
    AuthenticatedUserContext,
  );
  const [accessToken, setAccessToken] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (trigger) {
      fetchNotifications();
      addNewCount(newCount ? newCount + 1 : 1);
    }
  }, [trigger]);

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken());
    }

    if (firebase_user) {
      setupAccessToken();
    }
  }, [firebase_user]);

  useEffect(() => {
    if (accessToken) {
      fetchNotifications();
    }
  }, [accessToken]);

  const fetchNotifications = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/notifications/`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    fetch(url, options)
      .then((res) => {
        if (res.status === 401) {
          console.log("Unauthorized");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setNotifications(data.notifications);
      });
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotif(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotif(null);
  };

  return (
    <>
      <Tooltip onClick={handleOpenNotificationMenu} title="Notifications">
        <IconButton sx={{ p: 0, marginRight: 2 }}>
          <Badge
            color="secondary"
            badgeContent={newCount}
            invisible={!newCount}
          >
            <NotificationsActiveIcon sx={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        PaperProps={{
          style: {
            width: 500,
            maxWidth: 600,
          },
        }}
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElNotif}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElNotif)}
        onClose={handleCloseNotificationMenu}
      >
        <ListSubheader component="div">
          <Typography noWrap>Notifications</Typography>
        </ListSubheader>
        {notifications.map((notif) => (
          <ListItemButton style={{ whiteSpace: "normal" }} key={notif.id}>
            <ListItemText
              primary={
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: notif.message,
                  }}
                />
              }
              secondary={
                <Typography variant="caption" gutterBottom>
                  {dayjs(notif.datetime_created).format("lll")}
                </Typography>
              }
            ></ListItemText>
          </ListItemButton>
        ))}
      </Menu>
    </>
  );
}

export function NavBarNotification() {
  const { user } = useContext(AuthenticatedUserContext);
  const pubnub = usePubNub();
  const [channels, setChannels] = useState([]);
  const [messages, addMessage] = useState([]);
  const [latestMessage, updateLatestMessage] = useState();

  useEffect(() => {}, [updateLatestMessage]);

  useEffect(() => {
    if (user !== undefined && user !== null) {
      const profile = user;
      if (
        profile === undefined ||
        profile === null ||
        profile.pubnub_subscriptions === null ||
        !profile.pubnub_subscriptions
      ) {
        return;
      }
      const newChannels = profile.pubnub_subscriptions.split(",");
      setChannels([...new Set(channels.concat(newChannels))]);
    }
  }, [user]);

  useEffect(() => {
    const listener = {
      // <-- extract the listener
      message: (message) => {
        if (message && message.publisher !== user.pubnub_uuid) {
          addMessage((messages) => [message, ...messages]);
          updateLatestMessage(message);
        }
      },
    };

    pubnub.addListener(listener); // <-- pass the listener here
    pubnub.subscribe({ channels: channels });

    return function cleanup() {
      pubnub.removeListener(listener); // <-- pass the same listener here

      pubnub.unsubscribeAll();
      addMessage([]);
    };
  }, [channels]);

  useEffect(() => {
    messages.forEach((message) => {
      enqueueSnackbar(
        <Typography
          dangerouslySetInnerHTML={{
            __html: message.message,
          }}
        />,
        {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        },
      );
    });
  }, [messages]);

  return (
    <>
      <SnackbarProvider />
      <NotificationIconNav trigger={latestMessage} />
    </>
  );
}
