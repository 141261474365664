import React, {useContext, useEffect, useState} from "react"
import {DataGrid} from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import {BadgesForm, RolesForm} from "./forms";
import Toolbar from "@mui/material/Toolbar";
import Layout from "../layout";
import {AuthenticatedUserContext} from "../../context";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, Link} from "@mui/material";
import TextField from "@mui/material/TextField";
import {BadgesAutocomplete, RolesAutocomplete} from "./autocomplete";
import Typography from "@mui/material/Typography";

export function UsersPage() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <Users/>
    </Layout>
  )
}


export function Users() {
  const {isVerified, user, getBackendToken} = useContext(AuthenticatedUserContext)
  const [accessToken, setAccessToken] = useState(null)
  const [users, setUsers] = useState([])

  const get_users = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/?limit=1000&order_by=-datetime_created`;
    const options = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.static)
      }
    })
      .then(data => {
          setUsers(data.users)
        }
      )
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const setupAccessToken = async () => {
      setAccessToken(await getBackendToken())
    };

    if (users.length === 0 && isVerified && accessToken) {
      get_users()
    }

    if (isVerified && !accessToken) {
      setupAccessToken()
    }
  }, [isVerified, users, user, accessToken])


  const getRoleList = (params) => {
    if (!params.value) {
      return ""
    }
    const roles = params.value.map(role => {
      return role.name
    })
    return roles.join(", ")
  }

  const getBadgesList = (params) => {
    if (!params.value) {
      return ""
    }
    const roles = params.value.map(role => {
      return role.name
    })
    return roles.join(", ")
  }

  const editRoles = (params) => {
    const updateCellParams = {
      id: params.id,
      field: params.field,
    }
    return (
      <RolesForm
        updateCellParams={updateCellParams}
        user_id={params.row.id}
        selectedRoles={params.value}/>
    )
  }
  const showUsername = (params) => {
    return (
      <Link href={`${window.location.protocol}/admin/users/${params.value}`} underline={"none"}>
         <Typography>{params.value}</Typography>
      </Link>
    )
  }

  const editBadges = (params) => {
    const updateCellParams = {
      id: params.id,
      field: params.field,
    }
    return (
      <BadgesForm
        updateCellParams={updateCellParams}
        user_id={params.row.id}
        selectedBadges={params.value}/>
    )
  }

  const columns = [
    {
      field: 'display_name',
      headerName: 'Name',
      width: 300,
      renderCell: showUsername,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      editable: true,
      renderEditCell: editRoles,
      renderCell: getRoleList,
    },
    {
      field: "uid",
      headerName: "UID",
      flex: 1,
      editable: false,
    },
    {
      field: "badges",
      headerName: "Badges",
      flex: 1,
      editable: true,
      renderEditCell: editBadges,
      renderCell: getBadgesList,
    }
  ]
  return (
    <Box>
      <AddUserFormDialog onSuccess={get_users}/>
      {users.length > 0 ?
        <DataGrid
          autoHeight
          rowHeight={80}
          rows={users}
          columns={columns}/>
        :
        <div>Loading...</div>
      }
    </Box>
  );
}


export default function AddUserFormDialog(props) {
  const {onSuccess} = props;
  const [open, setOpen] = useState(false);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [username, setUsername] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const {firebase_user, getBackendToken} = React.useContext(AuthenticatedUserContext);

  useEffect(() => {
    const setupAccessToken = async () => {
      setAccessToken(await getBackendToken())
    };

    if (firebase_user) {
      setupAccessToken()
    }

  }, [firebase_user])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUsername(null)
    setSelectedRoles([])
    setSelectedBadges([])
    setOpen(false);
  };

  const addUserForm = () => {
    const data = {
      display_name: username,
      roles: selectedRoles.map(role => role.id),
      badges: selectedBadges.map(badge => badge.id)
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/system-users/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data)
    }
    fetch(url, options).then(response => {
      if (response.status === 401) {
        console.log("Unauthorized");
      } else if (response.status === 200) {
        handleClose()
        onSuccess()
      } else {
        const message = "Cannot create this user."
        alert(message)
        throw new Error(message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <div>
      <Button onClick={handleClickOpen}>
        Add user
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="display_name"
            label="Username"
            fullWidth
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
          <RolesAutocomplete
            sx={{border: 0, paddingTop: 1}}
            id="id_roles"
            name="roles"
            value={selectedRoles}
            fullWidth
            onChange={(e, selectedRoles) => {
              setSelectedRoles(selectedRoles);
            }}
          />
          <BadgesAutocomplete
            sx={{border: 0, paddingTop: 1}}
            id="id_badges"
            name="badges"
            fullWidth
            value={selectedBadges}
            onChange={(e, selectedBadges) => {
              setSelectedBadges(selectedBadges);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color={"error"} onClick={handleClose}>Cancel</Button>
          <Button onClick={addUserForm}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}