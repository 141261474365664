import React, {useContext, useEffect, useState} from "react";
import {FormControl, FormHelperText, Input, InputLabel} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {AuthenticatedUserContext} from "../../context";
import {useAuth0} from "@auth0/auth0-react";

export function ResendEmail(props) {
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = React.useState(null);
  const {getBackendToken} = useContext(AuthenticatedUserContext)
  const {isLoading} = useAuth0();

  useEffect(() => {
    async function setupToken() {
      if (!isLoading) {
        setAccessToken(await getBackendToken())
      }
    }

    setupToken()
  }, [isLoading])

  const handleClick = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/v2/auth/resend-email-verification/`
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };

    fetch(url, options).then((response) => {
      if (response.status === 200) {
        return response.json()
      } else {
        throw Error()
      }
    }).then((data) => {
      setMessage(data.message);
      setSeverity("success");
      setShowMessage(true);
      setLoading(false);
    }).catch(error => {
      console.log(error)
    })

  }
  return (
    <Box>
      {showMessage ? <Alert severity={severity}>{message}</Alert> : null}
      {loading ? <LoadingButton
          loading
        >Resend email
        </LoadingButton> :
        <Button
          onClick={handleClick} aria-label="save">
          <Typography sx={{marginX: 1}}>Resend email</Typography>
        </Button>
      }
    </Box>
  )
}

export function EmailVerification(props) {
  const [emailVerified, setEmailVerified] = useState(props.emailVerified || false);
  const {user} = useAuth0()

  const sendEmailDisplay = () => {
    return (
      <>
        <Typography variant="h6">We sent you an email for verification</Typography>
        <Typography>Please check your email and click on the verification link to verify your email
          address.
        </Typography>
        <Alert severity="info">{user.email}</Alert>
        <ResendEmail email={props.email} password={props.password}/>
      </>
    )
  }
  return (
    <Box>
      {!emailVerified ? sendEmailDisplay() : null}
    </Box>
  )
}


export function DoximityLink(props) {
  const [authUrl, setAuthUrl] = useState(null)
  const [accessToken, setAccessToken] = React.useState(null);
  const {getBackendToken} = useContext(AuthenticatedUserContext)
  const {isLoading} = useAuth0();

  const buttonStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url(https://assets.doxcdn.com/image/upload/v1/apps/doximity/api/api-button-verify-with-doximity-dark.png)",
    marginY: 2,
    height: 40,
  }

  useEffect(() => {
    async function setupToken() {
      if (!isLoading) {
        setAccessToken(await getBackendToken())
      }
    }

    setupToken()
  }, [isLoading])

  useEffect(() => {
    if (!props.done && accessToken) {
      getDoximityAuthLink()
    }
  }, [props, accessToken])

  const getDoximityAuthLink = () => {
    const options = {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v2/auth/doximity/authorization-url/`
    fetch(url, options).then(response => {
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 409) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    }).then(data => {
      if ("authorization_url" in data) {
        setAuthUrl(data.authorization_url)
      } else if ("badges" in data) {
        props.successCallback(data.badges)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const style = {
    marginY: 3,
  }
  const noteStyle = {
    fontSize: 12
  }

  return (
    !props.done ? (
      <Box sx={style}>
        <Typography sx={noteStyle}>Connect with Doximity to verify your medical details</Typography>
        <Button fullWidth sx={buttonStyle} href={authUrl}/>
        <Typography sx={noteStyle}>
          Note - we only use Doximity to validate your medical credentials such as MD, RN, Cardiology etc.
          We only do this once and we do not store any personal information. Doing this enables you to be anonymous,
          but
          verified
        </Typography>
      </Box>
    ) : null
  )
}

export function UsernameForm(props) {
  const [username, setUsername] = useState('')
  const [error, setError] = useState(null)
  const [valid, setValid] = useState(false)
  const [accessToken, setAccessToken] = React.useState(null);
  const {getBackendToken} = useContext(AuthenticatedUserContext)
  const {isLoading} = useAuth0();

  const handleChange = (newUsername) => {
    setUsername(newUsername)
  }

  useEffect(() => {
    async function setupToken() {
      if (!isLoading) {
        setAccessToken(await getBackendToken())
      }
    }

    setupToken()
  }, [isLoading])


  const handleSubmit = async (event) => {
    event.preventDefault()
    if (username === '' || username === null) {
      setError("Username cannot be empty")
    } else {
      await updateUsername()
    }
  }

  const updateUsername = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify({display_name: username})
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v2/auth/display_name/`
    await fetch(url, options).then(async response => {
      if (response.status === 200) {
        return response.json()
      } else {
        const error = await response.json()
        throw new Error(error.detail.message)
      }
    }).then(async data => {
      setError(null)
      setValid(true)
      props.successCallback(username)
    }).catch(error => {
      setError(error.message)
    })
  }

  const noteStyle = {
    fontSize: 12,
    marginTop: 1
  }

  return (
    <Box>
      <Typography variant="body2">You have earned the badges: </Typography>
      {props.badges.map(
        badge => <Typography key={badge.id} variant="body2"><strong>{badge.name}, </strong></Typography>)
      }
      <Typography sx={noteStyle}>Choose a username. Pick one that keeps you fairly anonymous</Typography>
      <FormControl sx={{marginY: 1}} fullWidth disabled={props.disable} error={!!error} variant="standard">
        <InputLabel htmlFor="component-error">Username</InputLabel>
        <Input
          required
          id="id_username"
          label="Username"
          placeholder="username"
          name="username"
          variant="standard"
          value={username}
          onChange={event => {
            handleChange(event.target.value)
          }}
        />
        {error ?
          <FormHelperText id="component-error-text">{error}</FormHelperText> :
          null
        }
        <FormHelperText
          error={false}>{"Username should be 6-15 characters, only letters, numbers and dashes and underscores. Cannot contain 'anonymed' or 'admin'. Has to start with a alphabet and cannot end with an underscore(_) or dash(-)."}</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit}>Done</Button>
    </Box>
  )
}
