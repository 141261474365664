import Toolbar from "@mui/material/Toolbar";
import React, {useContext, useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import {
  Avatar,
  CardActions,
  Chip,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import Layout from "../layout";
import {AuthenticatedUserContext} from "../../context";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from "@mui/material/Typography";


export function getProfiles(accessToken){
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
  }
  const url = `${process.env.REACT_APP_API_URL}/api/v1/profiles/`
  return fetch(url, options).then((response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 401) {
      alert("Unauthorized. Please login again.");
    } else {
      return response.json().then((data) => {throw data})
    }
  }).catch(error => {
    alert(error.detail.message)
  });
}


export function ProfileForm(props){
  const {submitIcon, label, targetAPI, defaultValue, successMessage, successCallback, data, edit} = props
  const [username, setUsername] = useState(defaultValue)
  const {getBackendToken} = useContext(AuthenticatedUserContext);
  const [accessToken, setAccessToken] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [colorText, setColorText] = useState("primary")
  const [helpText, setHelpText] = useState(null)
  useEffect(() => {
    const setupAccessToken = async () => {
      setAccessToken(await getBackendToken())
    };
    setupAccessToken()
  }, [])
  const send = () => {
    const options = {
      method: edit ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({...data, display_name: username}),
    }
    const url = targetAPI || `${process.env.REACT_APP_API_URL}/api/v1/profiles/display-name/`
    fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        return response.json().then((data) => {throw data})
      }
    })
      .then(newData => {
        setErrorMessage(null)
        setColorText("success")
        setHelpText(successMessage || "Username has been updated.")
        if (successCallback) {
          edit === true ? successCallback(data.profile_id, newData) : successCallback(newData);
        }
      })
      .catch(error => {
        setErrorMessage(error.detail.message)
      });
  }
  return(
    <Box>
      <TextField
        focused
        color={colorText}
        label={label || "Username"}
        id="display_name_id"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="button" onClick={send}>
                {submitIcon || <CheckIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={username}
        onChange={(event) => {
          setUsername(event.target.value);
        }}
        helperText={errorMessage || helpText}
        error={!!errorMessage}
        variant="standard"
      />
    </Box>
  )
}


export function ProfileItem(prop){
  const {profileId, username, isCurrent, setDefaultProfile, deleteProfile, targetAPI, data, successEditCallback} = prop

  return (
    <Card variant="outlined" style={{display: 'flex', flexDirection: "column", height: 200, width: 250}}>
      <CardContent>
        <Avatar sx={{marginBottom: 2}} alt="Remy Sharp" src="https://static-00.iconduck.com/assets.00/profile-default-icon-512x511-v4sw4m29.png" />
         {isCurrent ?
          <ProfileForm defaultValue={username} targetAPI={targetAPI} data={data} edit={true} successCallback={successEditCallback} />: <Typography>{username}</Typography>}
      </CardContent>
      <CardActions disableSpacing sx={{ mt: "auto" }}>
        <IconButton edge="end" aria-label="delete" onClick={() => deleteProfile(profileId)}>
          <DeleteIcon color={'error'}/>
        </IconButton>
        {isCurrent ? <Chip sx={{marginX: 2}} label="Current" color={'success'} /> : <IconButton edge="end" aria-label="current" onClick={() => setDefaultProfile(profileId)}>
          <CheckCircleIcon color={'info'}/>
        </IconButton>}
      </CardActions>
    </Card>
  )
}

export function Profile(props){
  const {data, fetchAPI, deleteAPI, setProfileURL, createNewProfile} = props;
  const {getBackendToken, user} = useContext(AuthenticatedUserContext);
  const [accessToken, setAccessToken] = useState(null)
  const [profiles, setProfiles] = useState([])
  const newProfile = createNewProfile || `${process.env.REACT_APP_API_URL}/api/v1/profiles/`
  useEffect(() => {
  }, [user])

  const getProfiles = () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
    }
    const url = fetchAPI || `${process.env.REACT_APP_API_URL}/api/v1/profiles/`
    fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        return response.json().then((data) => {throw data})
      }
    })
      .then(data => {
        setProfiles(data.profiles)
      })
      .catch(error => {
        alert(error.detail.message)
      });
  }

  useEffect(() => {
    const setupAccessToken = async () => {
      setAccessToken(await getBackendToken())
    };
    if (!accessToken){
      setupAccessToken()
    } else {
      getProfiles()
    }
  }, [accessToken])

  useEffect(() => {
  }, [profiles])

  const setDefaultProfile = (profile_id) => {
    const url = setProfileURL || `${process.env.REACT_APP_API_URL}/api/v1/profiles/current/`
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({...data, profile_id: profile_id}),
    }
    fetch(url, options).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        return response.json().then((data) => {throw data})
      }
    })
      .then(data => {
        getProfiles()
      })
      .catch(error => {
        alert(error.detail.message)
      });
  }
  const deleteProfile = (profileId) => {
    const url = deleteAPI ? `${deleteAPI}${profileId}` : `${process.env.REACT_APP_API_URL}/api/v1/profiles/${profileId}`
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then((response) => {
      if (response.status === 204) {
        getProfiles()
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        return response.json().then((data) => {throw data})
      }
    })
      .catch(error => {
        alert(error.detail.message)
      });
  }

  const createSuccessCallback = (newData) => {
    setProfiles([...profiles, newData])
  }
  const editSuccessCallback = (removeDataID, newData) => {
    let newProfiles = profiles.filter(obj => obj.id !== removeDataID)
    console.log(newProfiles)
    setProfiles([newData, ...newProfiles])
  }
  return (
    <Box sx={{ minWidth: 275, marginX: 3 }}>
      <Box sx={{marginY: 2}}>
        <ProfileForm data={data} label={"Add anonymous profile"} successMessage={"Profile created."} submitIcon={<AddIcon/>} targetAPI={newProfile} successCallback={createSuccessCallback}/>
      </Box>

      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        {!!user && profiles ? profiles.map((profile) => (
          <Grid item key={profile.display_name}>
            <ProfileItem
              data={{profile_id: profile.id}}
              targetAPI={createNewProfile ? createNewProfile : `${newProfile}${profile.id}/`}
              username={profile.display_name}
              profileId={profile.id}
              setDefaultProfile={setDefaultProfile}
              deleteProfile={deleteProfile}
              successEditCallback={editSuccessCallback}
              isCurrent={profile.is_current}/>
          </Grid>
        )) : null}
      </Grid>
    </Box>
  )
}

export function ProfilePage(){
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <Profile/>
    </Layout>
  )
}
